<template>
  <div class="box1">
    <div class="toutu">
      <el-carousel
        :interval="5000"
        arrow="always"
        height="530px"
        class="background"
      >
        <el-carousel-item>
          <!-- <img
            @click="dialogVisible = true"
            :class="index == 3 ? 'img imgs' : index == 1 ? 'img imgss' : 'img'"
            :src="item.url"
            alt=""
          /> -->
          <router-link
            to="/allcourses?type=2&name=职业培训免费学&id=434&is_type=2"
          >
            <img class="img imgss" :src="imglist[0].url" alt="" />
          </router-link>
        </el-carousel-item>
        <!-- <el-carousel-item>
          
        
            <img class="img" :src="imglist[0].url" alt="" />
          
        </el-carousel-item> -->
        <!-- <el-carousel-item>
          <img
            @click="dialogVisible = true"
            class="img imgss"
            :src="imglist[1].url"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            @click="dialogVisible = true"
            class="img"
            :src="imglist[2].url"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            @click="dialogVisible = true"
            class="img imgs"
            :src="imglist[3].url"
            alt=""
          />
        </el-carousel-item> -->
      </el-carousel>
    </div>
    <div style="height: 60px"></div>
    <div class="module">
      <div class="whhh">
        <div class="module_1">
          <div class="w">
            <div class="title">帮助企业建立数智化培训系统</div>
            <div class="brief">
              用智能技术为培训赋能，助力企业实现数字化转型
            </div>
            <div class="project">
              <div @mouseenter="clear1(1)" @mouseleave="add">
                <img
                  v-if="active1 != 1"
                  ref="img1"
                  src="../../assets/xitongforclickicon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img1"
                  src="../../assets/xitong_click_icon.png"
                  alt=""
                /><br />
                <span>智能系统</span>
              </div>
              <div @mouseenter="clear1(2)" @mouseleave="add">
                <img
                  v-if="active1 != 2"
                  ref="img2"
                  src="../../assets/zhike_icon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img2"
                  src="../../assets/zhike_click_icon.png"
                  alt=""
                /><br />
                <span>智能制课</span>
              </div>
              <div @mouseenter="clear1(3)" @mouseleave="add">
                <img
                  v-if="active1 != 3"
                  ref="img3"
                  src="../../assets/xuexi_icon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img3"
                  src="../../assets/xuexi_click_icon.png"
                  alt=""
                /><br />
                <span>智能学习</span>
              </div>
              <div @mouseenter="clear1(4)" @mouseleave="add">
                <img
                  v-if="active1 != 4"
                  ref="img4"
                  src="../../assets/kaoping_icon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img4"
                  src="../../assets/kaoping_click_icon.png"
                  alt=""
                /><br />
                <span>智能考评</span>
              </div>
              <div @mouseenter="clear1(5)" @mouseleave="add">
                <img
                  v-if="active1 != 5"
                  ref="img5"
                  src="../../assets/ketang_icon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img5"
                  src="../../assets/ketang_click_icon.png"
                  alt=""
                /><br />
                <span>直播课堂</span>
              </div>
              <div @mouseenter="clear1(6)" @mouseleave="add">
                <img
                  v-if="active1 != 6"
                  ref="img6"
                  src="../../assets/guanli_icon.png"
                  alt=""
                />
                <img
                  v-else
                  ref="img6"
                  src="../../assets/guanli_click_icon.png"
                  alt=""
                /><br />
                <span>智能管理</span>
              </div>
            </div>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 1">
                <img src="../../assets/1.png" alt="" />
                <div>
                  <div class="title1">一键开启培训</div>
                  <div class="brief1">
                    智能化系统赋能企业建立在线学习平台，支持大量学员同时在线学习，实现培训管理全流程线上化
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 2">
                <img src="../../assets/2.png" alt="" />
                <div>
                  <div class="title1">智能制课 轻松学习</div>
                  <div class="brief1">
                    根据企业提供的课程资源快速高效生成课件，满足各行各业不同企业个性化培训需求
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 3">
                <img src="../../assets/3.png" alt="" />
                <div>
                  <div class="title1">智能学习</div>
                  <div class="brief1">
                    支持PC端、微信公众号H5、微信小程序等多种端口在线学习，满足学员各场景的在线学习。
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 4">
                <img src="../../assets/4.png" alt="" />
                <div>
                  <div class="title1">考试评价 在线完成</div>
                  <div class="brief1">
                    智能评分，以考促学。在线评价，随堂调研
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 5">
                <img src="../../assets/5.png" alt="" />
                <div>
                  <div class="title1">直播课堂 实时互动</div>
                  <div class="brief1">
                    智能直播教学，最大化利用教学资源。实时互动，经验分享。
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="imgcontent" v-if="active1 == 6">
                <img src="../../assets/6.png" alt="" />
                <div>
                  <div class="title1">班级管理+数据报表</div>
                  <div class="brief1">
                    可实现班级在线化管理，可实时登录跟踪学员学习情况，可随时导出数据报表以便筛选管理，可汇总数据报表以供申请补贴
                  </div>
                  <div class="btnzx" @click="dialogVisible = true">
                    立即咨询
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 800px"></div>
    <div class="module1">
      <div class="w">
        <div class="announcement">
          <div class="item">
            <img
              style="width: 39px; height: 39px"
              src="../../assets/butie_icon.png"
              alt=""
            />
            <div>
              <div>享受政府补贴，一键备案。</div>
              <div>满足“四有两可”管理要求</div>
            </div>
          </div>
          <div class="item">
            <img
              style="width: 36px; height: 39px"
              src="../../assets/qiyekecheng_icon.png"
              alt=""
            />
            <div>
              <div>企业课程受版权保护</div>
              <div>仅供员工学习使用</div>
            </div>
          </div>
          <div class="item">
            <img
              style="width: 36px; height: 46px"
              src="../../assets/xitonganquan_icon.png"
              alt=""
            />
            <div>
              <div>具有信息系统安全</div>
              <div>二级等保备案认证</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module2">
      <div class="w">
        <div class="tltie2">为企业提供一站式培训补贴服务</div>
        <div class="brief">
          资深的服务团队为企业提供一站式培训补贴解决方案，持续的技术升级为企业提供更智能、更稳定、更安全的在线培训系统
        </div>
        <div class="brieflist">
          <div
            @mouseenter="active = 1"
            :class="active == 1 ? 'item active' : 'item'"
          >
            <img
              v-if="active != 1"
              src="../../assets/tuandui_icon.png"
              alt=""
            />
            <img v-else src="../../assets/tuandui_select_icon.png" alt="" />
            <div class="item_1">资深的服务团队</div>
            <div class="item_2">
              来自国内外知名企业的多名英才及资深的职业培训领域专家所打造的专业服务团队，为您提供更成熟，更落地企业培训服务
            </div>
          </div>
          <div
            @mouseenter="active = 2"
            :class="active == 2 ? 'item active' : 'item'"
          >
            <img
              v-if="active == 2"
              src="../../assets/yizhanshi_select_icon.png"
              alt=""
            />
            <img v-else src="../../assets/fangan_icon.png" alt="" />
            <div class="item_1">一站式解决方案</div>
            <div class="item_2">
              为企业提供一站式企业培训方案、系统工具及补贴服务，帮助企业快速培训落地。资深业内专家全过程跟踪，更便捷、更成熟、更省心。
            </div>
          </div>
          <div
            @mouseenter="active = 3"
            :class="active == 3 ? 'item active' : 'item'"
          >
            <img v-if="active != 3" src="../../assets/fuwu_icon.png" alt="" />
            <img v-else src="../../assets/fuwu_select_icon.png" alt="" />
            <div class="item_1">全面的产品与服务</div>
            <div class="item_2">
              产品功能满足企业在线培训全过程应用。建立以用户为中心，以员工职业技能提升为导向，共享优质的在线培训服务与内容生态。
            </div>
          </div>
          <div
            @mouseenter="active = 4"
            :class="active == 4 ? 'item active' : 'item'"
          >
            <img
              v-if="active != 4"
              src="../../assets/shengji_icon.png"
              alt=""
            />
            <img v-else src="../../assets/shengji_select_icon.png" alt="" />
            <div class="item_1">持续的技术升级</div>
            <div class="item_2">
              依托职培通数据支持和持续的科研投入，AI智能算法、大数据分析等新技术以更快的速度迭代升级。
            </div>
          </div>
          <div
            @mouseenter="active = 5"
            :class="active == 5 ? 'item active' : 'item'"
          >
            <img v-if="active != 5" src="../../assets/anqun_icon.png" alt="" />
            <img v-else src="../../assets/anqun_select_icon.png" alt="" />
            <div class="item_1">更稳定 更安全</div>
            <div class="item_2">
              以阿里云云应用为支持，以信息系统安全等保备案认证为保障，提供更稳定、更安全、更可靠的服务，为客户在线培训保驾护航。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module3">
      <div class="w">
        <div class="title3">多功能 全场景 智能化 培训赋能</div>
        <div class="modulelist">
          <img class="items" src="../../assets/yunketang_icon.png" alt="" />
          <img
            class="items"
            src="../../assets/kechengzhuanti_icon.png"
            alt=""
          />
          <img
            class="items"
            src="../../assets/kechengneirong_icon.png"
            alt=""
          />
          <img class="items" src="../../assets/kaoshiguanli_icon.png" alt="" />
          <img class="items" src="../../assets/xuexiguanli_icon.png" alt="" />
          <img class="items" src="../../assets/dhujubaobiao_icon.png" alt="" />
          <img class="items" src="../../assets/yinpintingke_icon.png" alt="" />
          <img class="items" src="../../assets/shipinxuexi_icon.png" alt="" />
          <img class="items" src="../../assets/h5xuexi_icon.png" alt="" />
          <img class="items" src="../../assets/zuotikaoshi_icon.png" alt="" />
          <img class="items" src="../../assets/dayifudao_icon.png" alt="" />
          <img class="items" src="../../assets/zuotikaoshi_icon.png" alt="" />
          <img
            class="items"
            src="../../assets/xaingmushenbao_icon.png"
            alt=""
          />
          <img class="items" src="../../assets/peixunfangan_icon.png" alt="" />
          <img class="items" src="../../assets/beianmoyi_icon.png" alt="" />
          <img class="items" src="../../assets/peixunshishi_icon.png" alt="" />
          <img class="items" src="../../assets/kaohebangzhu_icon.png" alt="" />
          <img class="items" src="../../assets/butiegenzong_icon.png" alt="" />
        </div>
        <div class="Immediately" @click="dialogVisible = true">立即咨询</div>
      </div>
    </div>
    <div class="module4">
      <div class="w">
        <div class="title4">他们都在用职培通</div>
        <div class="logolist">
          <img class="item_3" src="../../assets/pinpai13_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai2_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai3_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai4_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai5_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai6_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai7_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai8_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai9_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai10_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai11_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai12_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai1_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai14_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai15_imgs.png" alt="" />
          <img class="item_3" src="../../assets/pinpai16_imgs.png" alt="" />
        </div>
      </div>
    </div>
    <div class="module5">
      <div class="whhh">
        <div class="statistics">
          <div class="w">
            <div class="statisticslist">
              <div>
                <div class="statisticslist_item_1">注册会员数</div>
                <div class="statisticslist_item_2">
                  <span>{{ n1 }}</span
                  >万+
                </div>
              </div>
              <div>
                <div class="statisticslist_item_1">平台学习人次</div>
                <div class="statisticslist_item_2">
                  <span>{{ n2 }}</span
                  >万+
                </div>
              </div>
              <div>
                <div class="statisticslist_item_1">在线课程</div>
                <div class="statisticslist_item_2">
                  <span>{{ n3 }}</span
                  >+
                </div>
              </div>
              <div>
                <div class="statisticslist_item_1">合作企业</div>
                <div class="statisticslist_item_2">
                  <span>{{ n4 }}</span
                  >+
                </div>
              </div>
            </div>
            <!-- <div class="source">
              数据来源：职培通后台大数据统计（数据截止2021-05-15）
            </div> -->
            <div class="sybtn" @click="dialogVisible = true">
              立即开始使用职培通
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
    <div style="height: 437px"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      flag: true,
      flag1: true,
      active: 1,
      active1: 1,
      time: null,
      n1: 50,
      n2: 300,
      n3: 1000,
      n4: 1500,
      c1: null,
      c2: null,
      c3: null,
      c4: null,
      imglist: [
        {
          url: require("../../assets/home.png"),
        },
        // {
        //   url: require("../../assets/shouye1.png"),
        // },
        // {
        //   url: require("../../assets/shouye2.png"),
        // },
        // {
        //   url: require("../../assets/shouye3.png"),
        // },
        // {
        //   url: require("../../assets/shouye4.png"),
        // },
      ],
    };
  },
  methods: {
    ...mapActions(["getindex_x", "getphoneIndex_x"]),
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
    zx() {
      this.$router.push("/contact");
    },
    // 鼠标移入动画
    clear1(val) {
      this.active1 = val;
      clearInterval(this.time);
    },
    // 鼠标移出动画
    add() {
      this.time = setInterval(() => {
        if (this.active1 == 6) {
          this.active1 = 1;
          return;
        }
        ++this.active1;
      }, 3000);
    },
    // img(ref) {
    //   let img = null;
    //   if (ref == "img1") img = require("../../assets/xitong_click_icon.png");
    //   if (ref == "img2") img = require("../../assets/zhike_click_icon.png");
    //   if (ref == "img3") img = require("../../assets/xuexi_click_icon.png");
    //   if (ref == "img4") img = require("../../assets/kaoping_click_icon.png");
    //   if (ref == "img5") img = require("../../assets/ketang_click_icon.png");
    //   if (ref == "img6") img = require("../../assets/guanli_click_icon.png");

    //   this.$refs[ref].src = img;
    // },
    // img1(ref) {
    //   let img = null;
    //   if (ref == "img1") img = require("../../assets/xitongforclickicon.png");
    //   if (ref == "img2") img = require("../../assets/zhike_icon.png");
    //   if (ref == "img3") img = require("../../assets/xuexi_icon.png");
    //   if (ref == "img4") img = require("../../assets/kaoping_icon.png");
    //   if (ref == "img5") img = require("../../assets/ketang_icon.png");
    //   if (ref == "img6") img = require("../../assets/guanli_icon.png");
    //   this.$refs[ref].src = img;
    // },
    //清除定时器
    clear() {
      clearInterval(this.c1);
      clearInterval(this.c2);
      clearInterval(this.c3);
      clearInterval(this.c4);
      clearInterval(this.c2);
    },
    //数字跳动
    tiaodong() {
      this.flag = false;
      this.flag1 = false;
      let b1 = this.n1;
      let b2 = this.n2;
      let b3 = this.n3;
      let b4 = this.n4;
      let a1 = 0;
      let a2 = 0;
      let a3 = 0;
      let a4 = 0;
      this.c1 = setInterval(() => {
        a1 += parseInt(b1 * 0.05);
        this.n1 = a1;
        if (a1 > b1) {
          clearInterval(this.c1);
          this.n1 = b1;
          this.flag1 = true;
        }
      }, 50);
      this.c2 = setInterval(() => {
        a2 += parseInt(b2 * 0.05);
        this.n2 = a2;
        if (a2 > b2) {
          clearInterval(this.c2);
          this.n2 = b2;
        }
      }, 50);
      this.c3 = setInterval(() => {
        a3 += parseInt(b3 * 0.05);
        this.n3 = a3;
        if (a3 > b3) {
          clearInterval(this.c3);
          this.n3 = b3;
        }
      }, 50);
      this.c4 = setInterval(() => {
        a4 += parseInt(b4 * 0.05);
        this.n4 = a4;
        if (a4 > b4) {
          clearInterval(this.c4);
          this.n4 = b4;
        }
      }, 50);
    },
  },
  mounted() {
    let that = this;
    // 数字动画
    window.onscroll = function () {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t > 3000) {
        if (that.flag) {
          that.tiaodong();
        }
      } else {
        if (that.flag1) {
          that.flag = true;
        }
      }
    };
    this.time = setInterval(() => {
      if (this.active1 == 6) {
        this.active1 = 1;
        return;
      }
      ++this.active1;
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.background {
  // background: url("../../assets/banner_background.png");
  background: url("../../assets/homebj.jpg");
  // background: url("../../assets/hunian-banner.png");
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.toutu {
  position: absolute;
  left: 50%;
  height: 530px;
  width: 1920px;
  transform: translateX(-50%);
}

.img {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 120px;
}
.imgs {
  margin-top: 110px;
}
.imgss {
  margin-top: 60px;
}
.imgsss {
  margin-top: 0px;
}
.module_1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.module {
  padding-top: 470px;
  .title {
    margin-top: 83px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    text-align: center;
  }
  .brief {
    margin-top: 14px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #87878c;
  }
  .project {
    margin: 40px;
    display: flex;
    justify-content: space-around;
    img {
      width: 80px;
      height: 80px;
    }
    span {
      display: block;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1d1d1e;
    }
  }
  .imgcontent {
    display: flex;
    .title1 {
      margin-left: 48.6px;
      margin-top: 44px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1d1d1e;
    }
    .brief1 {
      margin-left: 48.6px;
      margin-top: 14px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 26px;
      color: #8d8d8d;
    }
    .btnzx {
      cursor: pointer;
      margin-top: 93px;
      margin-left: 48.6px;
      width: 172px;
      height: 41px;
      background: #1276fb;
      border-radius: 35px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
      line-height: 41px;
      color: #ffffff;
    }
  }
}
.module1 {
  margin-top: 20px;
  height: 121px;
  background: #124480;
  .announcement {
    display: flex;
    justify-content: space-around;
    .item {
      margin-top: 36px;
      display: flex;
      img {
        transform: translateY(6px);
      }
      div {
        margin-left: 10px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;

        line-height: 25px;
      }
    }
  }
}
.module2 {
  padding-bottom: 137px;
  .tltie2 {
    margin-top: 84px;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .brief {
    margin-top: 14px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #87878c;
  }
  .brieflist {
    margin-top: 48px;
    display: flex;
    .item {
      cursor: pointer;
      background-color: #f7f7f7;
      .item_1 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
      .item_2 {
        margin-top: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      padding: 0 30px;
      box-sizing: border-box;
      flex: 1;
      height: 450px;
      img {
        display: block;
        margin: 85px auto 85px;
      }
    }
  }
}
.module3 {
  background: url("../../assets/shouye_beijingtu_imgs.png") no-repeat center
    center;
  overflow: hidden;
  .title3 {
    margin-top: 55px;
    margin-bottom: 52px;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .modulelist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .items {
      &:hover {
        transform: scale(1.1);
      }
      width: 181px;
      height: 142px;
      // background: #ffffff;
      // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
      // border-radius: 6px;
      margin-bottom: 40px;
      // .img_1 {
      //   display: block;
      //   margin: 10px auto 0;
      // }
      // .text {
      //   text-align: center;
      //   font-size: 16px;
      //   font-family: PingFangSC-Semibold, PingFang SC;
      //   font-weight: 600;
      //   color: #666666;
      // }
    }
  }
  .Immediately {
    margin: 0 auto;
    margin-bottom: 80px;
    width: 206px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    background: #1276fb;
    color: #fff;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 35px;
  }
}
.module4 {
  overflow: hidden;
  .title4 {
    margin-top: 46px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .logolist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item_3 {
      width: 25%;
    }
  }
}
.module5 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .statistics {
    position: relative;
    overflow: hidden;
    background: url("../../assets/dibu_banner.jpg");
    height: 437px;
    .statisticslist {
      margin-top: 79px;
      display: flex;
      justify-content: space-around;
      .statisticslist_item_1 {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .statisticslist_item_2 {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        span {
          font-size: 70px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    .source {
      margin-top: 78px;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .sybtn {
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 100px;
      display: inline-block;
      padding: 15px 100px;
      border-radius: 40px;
      font-size: 16px;
      border: 1px solid #fff;
      color: #ffffff;
    }
  }
}

.active {
  background-color: #1276fb !important;
  color: #ffffff;
}
</style>
